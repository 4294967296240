.topMenu
  width: 100%
  height: 35px
  background-color: rgb(51, 69, 255)
  position: relative

.leftHandSide
  position: absolute
  left: 0
  height: 35px
  width: 70%

.logoOuterBackground
  background-color: lightgrey
  border: 2px solid #2b2b2b
  left: 0
  height: 100%
  width: 42px
  box-sizing: border-box
  display: flex
  align-items: center
  justify-content: center

.logo
  height: 80%
  svg
    overflow: visible

.rightHandSide
  position: absolute
  right: 0
  height: 35px
  width: 30%
  display: flex
  flex-direction: row-reverse
  align-items: center

.authentication
  height: 100%
  position: relative

.notificationsIcon,.authenticationIcon
  height: 100%
  box-sizing: border-box
  margin: 0 5px

.notifications
  height: 100%
  box-sizing: border-box
  margin: 0 5px
  position: relative

.authenticationIcon
  padding: 2px 0

.notificationsIcon
  position: relative

.notificationsCentre
  position: fixed
  inset: (35px + 5px) 0 0 calc(100% - (380px + 10px))
  background-color: rgb(218,218,218)
  border: 1px solid black
  border-radius: 4px
  width: 380px
  height: 180px
  padding: 10px
  box-shadow: 2px 2px 1px 2px rgb(107, 107, 107, 0.5)
  border-radius: 3px
  display: flex
  flex-direction: column
  box-sizing: border-box