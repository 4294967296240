.authenticationIcon
  height: 100%
  box-sizing: border-box
  margin: 0 5px
  padding: 2px 0

.notifications
  height: 100%
  box-sizing: border-box
  margin: 0 5px
  position: relative  
  
.loginContainer
  padding: 10px
  margin: 5px

form
  display: flex
  flex-direction: column
  margin: 15px 12px 0 12px

  .formRow
    display: grid
    grid-template-columns: max-content 1fr
    align-items: center
    column-gap: 10px
    margin-bottom: 10px

    label
      // No width specified; adjusts to content

    input
      width: 100%   // Fills the grid column

  .submit
    input[type="submit"]
      width: 100%
      padding: 10px
      background-color: #ffffff
      color: #fff
      color: black
      border: none
      border: 1px solid grey
      cursor: pointer

      &:hover
        background-color: #aeaeae

.errorMessage
  height: calc(0.7rem + 12px)
  overflow: hidden
  display: flex
  align-items: center
  justify-content: center

.errorBackground
  height: fit-content
  width: fit-content
  background-color: rgb(227,106,113)
  color: white

