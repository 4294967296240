.container
  height: 1.5rem
  display: flex
  align-items: center
  border: solid 1px black
  border-radius: 2px
  background-color: white
  margin-bottom: -1px
  &.new
    background-color: white
  h4
    flex: 1 1 auto
    // font-size: 0.6rem
    text-transform: uppercase
    font-weight: 600
    // font-style: normal
    // line-height: 1.37rem
    margin-block-start: 0.11rem
    margin-block-end: 0
  svg
    flex: 0 1 0.65rem
    margin: 0 10px 0 0
    stroke: black
    stroke-width: 1.5px
    fill: yellow
  .detailsToggle
      height: 100%
      margin: 0 25px 0 10px
      display: flex
      align-items: center
      justify-content: center
      svg:first-of-type
        height: 32%
        stroke: black
        stroke-width: 1.5px



