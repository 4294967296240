.pageLayout
  position: relative
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  align-items: flex-start

.scrollContainer
  height: 100%
  width: fit-content
  position: relative
  overflow-y: auto
  display: flex
  justify-content: center
  align-items: flex-start
  padding: 2px
  box-sizing: border-box

.pageContainer
  width: 100%
  height: fit-content
  position: relative
  display: flex
  justify-content: center
  align-items: flex-start
  padding: 0 10px 10px 10px

.page
  height: fit-content
  width: 100%
  border: solid grey 1px
  border-radius: 2px
  position: relative
  background-color: white
  box-shadow: -9px 9px 2px 0px rgba(125, 125, 125, 0.3)
  display: flex
  justify-content: center
  align-items: flex-start

.contentContainer
  height: 100%
  height: fit-content
  width: 100%
  position: relative
  display: flex
  justify-content: center
  align-items: flex-start
