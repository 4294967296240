h2, h3
  font-family: 'Maison Neue'
  text-transform: uppercase
  font-size: 0.6rem
h4
  font-family: 'Maison Neue'
  font-size: 0.5rem
p
  font-size: 0.8rem
h5
  font-style: italic
  margin: 0.2rem 0
h2
  margin-bottom: 0
li
  font-size: 0.8rem
form
  input,textarea
    font-family: monospace
    font-size: 0.8rem
  label
    font-size: 0.6rem
    font-family: 'Maison Neue'
    text-transform: uppercase
    font-weight: 600
  input[type="submit"]
    font-family: 'Maison Neue'
    font-size: 0.6rem
    font-weight: 700
    cursor: pointer
.error
    font-size: 0.6rem
    text-align: center
    font-weight: 600
    font-family: 'Maison Neue'
    margin-block-start: 0.2rem
    margin-block-end: 0.23rem
    margin-inline: 0.4rem




