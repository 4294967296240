.background
  position: relative
  width: 100%
  height: 100%
  background-color: rgb(240, 240, 240)
  display: flex
  align-items: flex-start
  justify-content: center
  overflow-y: hidden
  box-sizing: border-box
  padding: 20px 0

.contentContainer
  width: calc(100% - 35px)
  height: 100%
  // width: 100%

