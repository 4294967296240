.checkBox
  width: 100%
  height: 100%
  svg
    width: 100%
    height: 100%
    rect
      stroke: black
      stroke-width: 2px

.checked
  svg
    rect
      fill: #a5a5a5

.unchecked
  svg
    rect
      fill: none
