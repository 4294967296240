.landingPage
  height: 100%
  width: 100%
  background-color: white
  display: flex
  align-items: center
  justify-content: center
  .logoContainer
    width: fit-content
    height: fit-content
    // svg
    //   width: calc(max(15vw, 35vh))
    //   max-width: 70vw
    //   max-height: 70vh
    svg
      width: calc(max(25vw, 35vh))
      max-width: 70vw
      max-height: 70vh
