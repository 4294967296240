.tabLayout
  position: relative
  height: 100%
  width: 100%
  display: flex
  flex-flow: column

.tabMenuContainer
  height: fit-content
  width: 100%
  flex: 0 0 auto