.contactWindow
  width: 100%
  height: 100%
  overflow-x: hidden
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box

  form
    width: 94%
    height: 100%
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    font-family: 'Maison Neue'
    font-weight: 700

textarea
  resize: none
  overflow-y: scroll
  overflow-x: hidden
  margin: 4px 4px 4px 0
  height: calc(100% - 8px)

textarea::-webkit-scrollbar
  width: 14px /* Width of the scrollbar */
  background-color: rgba(180, 180, 180, 0.01) /* A light scrollbar track */

textarea::-webkit-scrollbar-track
  width: 14px /* Width of the scrollbar */
  background: rgba(228, 228, 228, 0.1) /* A light scrollbar track that always shows */
  box-shadow: inset 3px 0 2px 0 rgba(0,0,0,0.05)
  border-radius: 10px

  /* Style the scrollbar handle */
textarea::-webkit-scrollbar-thumb
  background-color: rgb(210, 210, 210) /* A greyed-out scrollbar handle */
  border: none
  border-radius: 10px

/* Style the scrollbar handle on hover or when active */
textarea::-webkit-scrollbar-thumb:hover,
textarea::-webkit-scrollbar-thumb:active
  background-color: rgba(148, 148, 148, 0.5) /* A more contrasted scrollbar handle */

  /* For Firefox */
.custom-textarea
  scrollbar-width: thin
  scrollbar-color: darkgrey rgba(0,0,0,0.1)

/* Hide the scrollbar when it's not necessary */
.custom-textarea
  overflow-y: auto /* Scrollbar will only appear when needed */

input,textarea
  background-color: rgb(240, 240, 240)
  border-radius: 2px
  outline: none
  box-sizing: border-box

input:not([type="submit"]),textarea
  border: none
  flex: 1 1 auto
  width: 0
  margin-left: 5px
  padding-left: 3px

input:not([type="submit"])
  height: 100%

label
  margin: 8px 4px 8px 8px

.contactMessage,.email

input[type="submit"]
  align-self: flex-end
  width: 70px
  height: 35px
  margin-right: 30px

input[type="submit"]
  border: 2px solid #484848
  margin: 6px

.section
  padding: 6px
  border: 1px solid black
  &.emailSection
    border-bottom: none

.section
  display: flex
  align-items: flex-start
  justify-content: flex-start
  width: 96%
  flex-flow: row nowrap

  &.messageSection
    flex: 1 1 auto

.labelContainer
  flex: 0 0 18%
  display: flex
  justify-content: start
  padding: 1px 0 0 0
  height: 100%

.topGap
  height: 15px

.bottomRow
  display: flex
  position: relative
  width: 100%
  height: 2rem
  justify-content: space-between
  margin-bottom: 6px

.bottomRight
  width: fit-content
  height: 100%
  position: relative
  flex: 1 1 0
  display: flex
  justify-content: right

.toastSection
  height: 100%
  p
    text-align: right
    line-height: 1rem
    margin-block: 0.5rem
    font-style: italic
    color: green
    margin-right: 10px
    font-size: 0.7rem

.altContact
  height: 100%
  justify-self: self-start
  p
    font-size: 0.6rem
    margin-left: 12px
    line-height: 0.75rem
    margin-block: 0.25rem
