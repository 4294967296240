.windowTop
  height: 35px
  width: 100%
  border-bottom: 1px solid rgba(0, 0, 0, 0.2)
  cursor: move
  display: flex
  justify-content: flex-start
  align-items: center
  top: 0
  left: 0
  position: relative
  &.fullScreen
    cursor: default

.topLeftContainer,.topRightContainer
  display: flex
  flex: 1 1 auto
  height: 100%
  align-items: center

.topRightContainer
  flex: 1 1 auto

.rightButtonGroup
  width: fit-content
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  padding-right: 5px
  position: relative

.leftButtonGroup
  width: fit-content
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  padding-left: 3px

// .fullScreenIconContainer, .closeButtonContainer, .minimiseIconContainer
.button
  height: 20px
  width: 20px
  cursor: pointer
  margin: 0
  box-sizing: border-box
  display: flex
  svg
    stroke-width: 2px

.fullScreenIconContainer
  padding: 4px
.closeButtonContainer
  padding: 4px
.minimiseIconContainer
  padding: 3px
