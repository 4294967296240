.searchFilters
  padding: 10px 0 0 20px
  overflow-y: auto
  border-top: 1px solid #ccc

  ul
    list-style-type: none
    padding: 0
    overflow-x: hidden
    overflow-y: hidden
    column-count: 1

  li
    display: flex
    align-items: center
    margin-left: 20px
    margin: 0 0 10px 0
    font-size: 0.6rem

    &:before
      content: ''
      display: inline-block

  h3
    margin-top: 10px

.subjects
  margin-right: 20px
