.appsWindow
  height: 100%
  width: 100%
  display: grid
  grid-template-rows: 1fr 1fr
  grid-template-columns: 1fr 1fr
  
  *
    box-sizing: border-box

.appGridItem
  display: flex
  align-items: center
  justify-content: center

.appContainer
  position: relative
  height: min-content
  width: min-content
  display: flex
  flex-flow: column
  align-items: center
  justify-content: center

.iconBackground
  position: absolute
  width: 100%
  height: 100%



.appIconContainer
  position: relative


.iconImage
  position: relative
  padding: 10%
  svg
    flex: 1 1 100%