.spinner_rXNP
  animation: spinner_YeBj .8s infinite

@keyframes spinner_YeBj
  0%
    animation-timing-function: cubic-bezier(0.33,0,.66,.33)
    cy: 5px
  46.875%
    cy: 20px
    rx: 4px
    ry: 4px
  50%
    animation-timing-function: cubic-bezier(0.33,.66,.66,1)
    cy: 20.5px
    rx: 4.8px
    ry: 3px
  53.125%
    rx: 4px
    ry: 4px
  100%
    cy: 5px

// source: https://github.com/n3r4zzurr0/svg-spinners/blob/main/svg-css/bouncing-ball.svg?short_path=6ba8ad5