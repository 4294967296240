.toolTipWrapper
  position: relative
  display: inline-block
  height: 100%

.toolTipBox
  position: absolute
  left: 50%
  white-space: nowrap
  background-color: lightgrey
  color: rgb(80,80,80)
  padding: 2px 4px
  border-radius: 2px
  z-index: 1000
  transform: translateX(-50%)
  opacity: 0
  transition: opacity 200ms ease-in

  h4
    margin: 4px
    text-transform: uppercase
    font-size: 0.45rem

.toolTipBox.active
  opacity: 1
  transition: opacity 200ms ease-in

.hidden
  display: none
