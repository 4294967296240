.dock
  width: 100%
  height: 70px
  position: relative

.backgroundBox
  width: 100%
  height: 100%
  position: relative
  svg
    position: absolute

.dockOverlay
  svg
    &:nth-child(1) // Top
      height: 6%
      width: 100%
    &:nth-child(2) // Bottom
      height: 6%
      width: 100%
      // top: 92%
      bottom: 0
    &:nth-child(3) // Left
      width: 0.15%
    &:nth-child(4) // Right
      width: 0.25%
      right: 0

.dockIcons
  position: absolute
  inset: 0
  width: 100%
  height: 100%
  display: flex

.leftIcons,.centerIcons
  height: 100%
  display: flex
  align-items: center
  justify-content: flex-start

.leftIcons
  justify-self: flex-start
  flex: 0.8 1 auto
  margin-left: 5px

.centerIcons
  flex: 1 1 auto

.dockIconBoxShadow
  height: fit-content
  width: fit-content
  position: relative
  height: 84%
  box-sizing: content-box
  margin-bottom: 2px
  box-shadow: 2px 2px 1px 0px rgb(168, 168, 168), -2px -2px 1px 0px rgb(168, 168, 168), 2px -2px 1px 0px rgb(168, 168, 168), -2px 2px 1px 0px rgb(168,168,168)
  display: flex
  padding: 0 1px
  &:first-of-type
    padding-left: 0
  &:last-of-type
    padding-right: 0

    
.dockIcon
  height: 100%
  box-sizing: border-box
  border: 1px solid rgba(0,0,0,0.1)
  box-shadow: inset 2px 2px 1px 0px rgb(190,190,190), inset -1px -1px 1px 0px rgb(170,170,170)
  position: relative
  padding: 0 4px
  cursor: pointer
  // &:hover
  //   background-color: yellow
  svg
    width: auto
    position: relative
