.profileWindow
  // width: 625px
  // height: 525px
  // padding-left: 8px
  display: flex
  width: 100%
  height: 100%
  position: relative

.leftSidePanel,.rightTabPanel
  position: relative
  height: 100%
  // flex: 1 1 auto
  // width: fit-content
  // position: absolute

.leftSidePanel
  width: 25%


.rightTabPanel
  flex: 1 1 auto


