// Define the base sizes
$areaCrossWidth: 4px // Thickness of the selectable zone for resizing
$cornerSpan: 4px // Determines the side lengths of the corner zones

// Calculated dimensions
$totalCornerLength: $areaCrossWidth + $cornerSpan
$overhang: (1/2) * $areaCrossWidth // Half of the areaCrossWidth hangs over the containing element

.resizingArea
  position: absolute
  z-index: 20

// SIZING
.end
  height: $areaCrossWidth
  &:not(.corner)
    width: calc(100% - 2 * (#{$totalCornerLength} - ((1/2) * #{$areaCrossWidth})))
  &.corner
    width: $totalCornerLength
.side
  width: $areaCrossWidth
  &.corner
    height: $cornerSpan
  &:not(.corner)
    height: calc(100% - 2 * (#{$overhang} + #{$cornerSpan}))

// POSITIONING (OUTER)
.end
  &.northern
    top: -$overhang
  &.southern
    bottom: -$overhang
.eastern
  right: -$overhang
.western
  left: -$overhang

// POSITIONING (INNER)
.end
  &:not(.corner)
    left: $totalCornerLength - $overhang
    &.eastern
      left: calc(100% - (4px + 2px))
.side
  &.northern
    top: $overhang
  &:not(.corner)
    top: $totalCornerLength - $overhang
  &.southern
    bottom: $overhang

// CURSORS 
.north
  cursor: n-resize
.northEast
  cursor: ne-resize
.east
  cursor: e-resize
.southEast
  cursor: se-resize
.south
  cursor: s-resize
.southWest
  cursor: sw-resize
.west
  cursor: w-resize
.northWest
  cursor: nw-resize


// CURSORS 
.north
  cursor: ns-resize
.northEast
  cursor: nesw-resize
.east
  cursor: ew-resize
.southEast
  cursor: nwse-resize
.south
  cursor: ns-resize
.southWest
  cursor: nesw-resize
.west
  cursor: ew-resize
.northWest
  cursor: nwse-resize


.resizingArea.fullScreen
  cursor: default

// FOR TESTING 

// .resizingArea
//   background-color: #0026ffcc !important

// .corner
//   background-color: #ff00009a !important

// .side:not(.corner)
//   background-color: #00ff0d9a !important
