.windowTopMobile
  height: 55px
  width: 100%
  border-bottom: 1px solid rgba(0, 0, 0, 0.2)
  display: flex
  position: relative

.centerContainer
  display: flex
  flex: 1 1 auto
  justify-content: center
  margin: 0 1%
  div
    display: flex
    align-items: center
    justify-content: center

.title
  padding: 0 2px

.strokesContainer
  flex: 1 1 auto
  margin: 6% 0

.leftContainer,.rightContainer
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  width: 20%
  margin: 0 3px

.rightContainer
  padding-right: 20px

.backArrowContainer
  height: 100%
  width: 100%
  position: relative

.infoButtonContainer
  position: relative
  height: 85%