.tabMenu
  width: 100%
  height: 65px
  // border-top: 1px solid grey
  // border-bottom: 1px solid lightgrey

.topSpace
  width: 100%
  height: 20px

.tabs
  position: relative
  width: 100%
  height: 100%
  display: flex
  flex: 1 0 auto

.section
  flex: 0 0 auto
  // display: flex
  justify-content: center
  align-items: center
  // max-width: 250px
  overflow-x: visible
  margin-right: -3px
  cursor: pointer
  // position: absolute
  // margin-left: -3px
  // border-right: 1px solid black
  // border-bottom: 1px solid black
  // border-top: 1px solid black
  // &>div
  //   position: absolute

  h2
    display: inline

.endSection
  // border-bottom: 1px solid black
  flex: 1 1 40px
  border-bottom: 1px solid black

.startSection
  // border-right: 1px solid black
  // border-bottom: 1px solid black
  flex: 1 1 40px
  border-bottom: 1px solid black
  z-index: 9

  // margin-left: -3px

.section:not(.currentSection),.endSection,.startSection,.topSpace, .tabMenu
  background-color: rgb(222,222,222)



.sectionShapePath,.sectionTitleContainer
  position: absolute
  height: 100%
  width: 20%
  display: flex
  align-items: center
  justify-content: center

.section
  height: 100%
  width: 20%
  // border-bottom: solid 1px grey
  h2, h3
    margin: 4px 0 0 0

.currentSection
  // border-bottom: none
  // clip-path: polygon(0% 100%, 5% 0%, 100% 0%, 95% 100%)
  // box-shadow: 1px 1px 3px 3px black
  // background-color: blue
  z-index: 10