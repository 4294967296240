.notificationsArea
  position: fixed
  inset: 35px 0
  width: 100%
  height: fit-content
  display: flex
  flex-flow: row-reverse
  
.notificationsIcon,.authenticationIcon
  height: 100%
  box-sizing: border-box
  margin: 0 5px

.notifications
  height: 100%
  box-sizing: border-box
  margin: 0 5px
  position: relative

.authenticationIcon
  padding: 2px 0

.notificationsIcon
  position: relative

.notificationsCentre
  position: relative
  width: 400px
  max-height: 220px
  height: fit-content
  padding: 10px
  display: flex
  flex-direction: column
  box-sizing: border-box
  margin: 5px 7px 0 0
  z-index: 10
  // overflow-y: hidden

  h3
    margin: 4px 0 0 6px
  //   display: flex
  //   align-items: center
  //   justify-content: center

  p
    color: rgb(100,100,100)
    font-family: 'Maison Neue', Arial, Helvetica, sans-serif
    font-style: italic
    font-size: 0.65rem

.listContainer
  // border: 1px solid black
  border-radius: 3px
  // height: 100%
  margin-top: 15px
  // background-color: rgb(212, 212, 212)
  // padding: 10px
  overflow-y: auto

.details
  position: relative
  top: 40px
  left: 0px
  z-index: 15
  // position: absolute
  // inset: 35px 0px 0px -393px
  // z-index: -1