@font-face
  font-family: 'Maison Neue'
  src: url('../../public/fonts/maisonneuebook.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Maison Neue'
  src: url('../../public/fonts/maisonneuebookitalic.ttf') format('truetype')
  font-weight: normal
  font-style: italic

@font-face
  font-family: 'Maison Neue'
  src: url('../../public/fonts/maisonneuebold.ttf') format('truetype')
  font-weight: bold
  font-style: normal

@font-face
  font-family: 'Maison Neue'
  src: url('../../public/fonts/maisonneuebolditalic.ttf') format('truetype')
  font-weight: bold
  font-style: italic

@font-face
  font-family: 'Maison Neue'
  src: url('../../public/fonts/MaisonNeueDemi.ttf') format('truetype')
  font-weight: 600 // You can adjust this value based on how you want to use the demi font
  font-style: normal
