.title
  font-weight: 50
  font-size: 0.6rem
  color: black
  margin-left: 5px
  margin-right: 5px

.strokes
  margin: 0 calc(4px + 3%)
  width: 100%
  height: 24%
  border-top: 1px solid RGB(20, 20, 20)
  border-bottom: 1px solid RGB(20, 20, 20)
