// .loadingBox
//   visibility: hidden

#desktop
  visibility: hidden

// .startButtonBox
//   transition: all 0.2s ease-in-out

.reflection
  pointer-events: none

.startText
  pointer-events: none

.startButtonBox,.startButtonBoxShadow,.startButtonBox,.startButtonGroup
  transition: fill,transform 0.2s ease-in-out
  transform-origin: center

.hidden
  // transition: visibility 2s ease-in-out
  visibility: hidden
  opacity: 0
  transition: opacity 2s ease

.visible
  visibility: visible
  opacity: 1

.startButtonGroup
  &:hover
    transform: scale(1.01) translate(-1px,-1px)
    .startText
      fill: white
    .startButtonBox
      fill: blue

@keyframes loadingAnimation
  from
    width: 4px
  to
    width: 256px

.loadingBox.visible
  .startLoad
    animation: loadingAnimation 1.8s cubic-bezier(0.6, 0.0, 0.05, 1) forwards
