.item
  position: relative
  display: flex
  width: 100%
  height: 100%

.sampleImage
  box-sizing: border-box
  max-width: 100%
  max-height: 100%
  padding: 20px
