.appContainer
  position: relative
  height: min-content
  width: min-content
  display: flex
  flex-flow: column
  align-items: center
  justify-content: center
  cursor: pointer

.iconBackground
  position: absolute
  width: 100%
  height: 100%

.appIconContainer
  position: relative


.iconImage
  position: relative
  padding: 10%
  svg
    flex: 1 1 100%