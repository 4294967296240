.container
  position: absolute //parent element should be positioned
  z-index: 100
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  width: min(550px, 100%)
  padding: 5px 10px 10px 5px
  box-sizing: border-box

.arrow
  margin: 0
  height: 10px
  // width: 10px
  stroke: black
  stroke-width: 1.5px
  display: flex
  align-items: center
  justify-content: center
  &:hover
    stroke-width: 2px
  // display: inline-block
  // line-height: 0
  // padding-left: 10px
  // svg
  //   height: 6px
  //   width: auto
  //   vertical-align: top
  //   overflow: visible
  //   *
  //     stroke: grey

.infoBox
  padding: 0
  display: flex
  flex-direction: column
  box-sizing: border-box
  width: 100%
  p
    font-family: "Maison Neue", Arial, Helvetica, sans-serif
    font-size: 0.7rem

  // p:not(:first-of-type)
  //   margin-top: 5px
  // p:not(:last-of-type)
  //   margin-bottom: 5px

.topRow
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid grey
  padding: 10px

.windowTitle
  flex: 1 1 auto
  h3
    font-family: "Maison Neue", Arial, Helvetica, sans-serif
    font-size: 0.5rem

.messageNumbers
  flex: 0 0 auto
  margin: 0 20px
  display: flex
  align-items: center
  justify-content: flex-end
  p
    font-family: "Maison Neue", Arial, Helvetica, sans-serif
    font-weight: bold
    font-kerning: 0.5
    font-size: 0.6rem
    display: inline-block
    margin-block: 0
    margin: 2px 5px 0 5px

.closeButtonContainer
  height: 0.6rem
  width: fit-content
  position: relative
  cursor: pointer
  svg
    vertical-align: top
    padding: 1px
    box-sizing: border-box

.messageBody
  margin: 5px 5px 5px 5px
  padding: 10px
  h4
    margin-block-start: 4px
    margin-block-end: 10px
  p
    margin-block-start: 8px
    margin-block-end: 4px
