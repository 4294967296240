.maximised
  padding: 10px 25px 5px 10px

.minimsed
  padding: 10px 2px 5px 2px
  
.slider
  display: flex
  align-items: center

  div:not(.current)
    svg
      circle
        stroke: white
  
  svg
    circle:hover
      fill: white



.circleContainer
  width: 25px
  height: 25px
  svg
    width: 100%
    height: 100%

h3
  margin: 1px 0 0 0

.current
  text-decoration: underline


