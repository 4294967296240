.background 
  position: absolute
  top: 35px
  bottom: 0
  width: 100%
  z-index: -1000
  display: flex
  flex-direction: column

.startGrid
  height: 40px
  width: 100%
  background-size: 40px 40px
  background-image: linear-gradient(to right, rgb(150, 150, 150) 2px, transparent 1px)

.grid
  width: 100%
  flex: 1 1 auto
  background-size: 40px 40px
  background-image: linear-gradient(to right, rgb(150, 150, 150) 2px, transparent 1px), linear-gradient(to bottom, rgb(150, 150, 150) 2px, transparent 1px)
  background-repeat: repeat

