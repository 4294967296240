.container
  width: 380px
  margin: 8px
  // padding: 5px
  // background-color: rgb(240,240,240)
  background-color: rgb(255,255,255)
  // box-shadow: none
  h3
    margin: 10px 10px 15px 15px
    text-transform: capitalize
    font-size: 0.46rem
    font-weight: 900
  table
    margin: 15px 15px 18px 15px
    // border: 1px solid black
    border-collapse: collapse
    border-radius: 2px
    width: calc(100% - 35px)
    box-sizing: content-box
  td
    font-family: 'Maison Neue', Arial, Helvetica, sans-serif
    font-size: 0.55rem
    font-weight: 520
    margin: 8px
  tr
    border: 1px solid black
    display: grid
    grid-template-columns: 65fr 100fr
    margin: -1px
    border-radius: 2px
    background-color: white
  // tr:first-of-type
  //   display: block
  //   border: none
  //   margin-bottom: 5px
  //   background-color: unset
    th
      text-align: start
      font-size: 0.65rem
      font-family: 'Maison Neue', Arial, Helvetica, sans-serif
      font-weight: 530
      padding: 5px 5px 8px 8px
  // tr:nth-of-type(2)
  //   display: block
  //   border: none
  //   margin-bottom: 5px
  //   background-color: unset
  //   th
  //     margin: 10px 10px 5px 15px
  //     text-transform: capitalize
  //     font-size: 0.46rem
  //     font-weight: 900

.topRow
  display: flex
  height: 1rem
  width: 100%
  align-items: center
  justify-content: right
  // padding: 0 0 12px 0
  // background-color: lightgrey
  // border-bottom: 1px solid darkgrey
  h3
    flex: 0 0 auto
    margin: 15px 20px 0 15px
    text-transform: capitalize
    font-style: italic
    font-size: 0.46rem
    font-weight: 900
  svg
    width: fit-content
    height: 32%
    margin: 0 10px 0 0
    stroke-width: 1.5px

