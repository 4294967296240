.tabMenuItem
  stroke: #000000
  stroke-width: 1px
  stroke-miterlimit: 10
  width: 100%
  height: 100%
  overflow-x: visible
  position: relative
  display: flex
  justify-content: center
  align-items: center
  h2
    position: absolute
    font-size: 0.5rem
    // top: 0
    // left: 0
    // bottom: 0
    // right: 0
    // margin: auto
    // width:50%
    // text-align: center

svg  
  overflow-x: visible

.currentTabSvgContainer
  position: absolute
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center

.divider
  position: absolute
  height: 33% 
  right: 0px

.tabContents
  position: relative
