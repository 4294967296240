.outerContainer
  display: flex
  align-items: center
  justify-content: center
  
  .labelBox
    padding: 6px 14px
    flex-grow: 0 
    text-align: center
    h3
      margin: 0
      padding: 0.1rem 0 0 0
      font-size: 0.5rem
      text-align: center
      font-weight: 700
      font-family: 'Maison Neue'
      // background-color: rgba(255,255,255,0.5)


.openLabel
    background-color: rgba(255,255,255,0.5)
