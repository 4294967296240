.portfolioWindow
  height: 100%
  width: 100%
  display: flex
  img
    width: 400px

.arrow
  width: 25px
  stroke-width: 4px
  stroke-linecap: round
  stroke: #d2d2d2ab
  align-self: flex-end
  justify-self: flex-end
  &:hover
    stroke: #d2d2d2
    stroke-width: 5px

.leftSidePanel
  background-color: #202020
  color: #d2d2d2
  padding: 25px 0px 0 0px
  display: flex
  flex-flow: column

.rightMainPanel
  flex: 1 1 auto
