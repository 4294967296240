.bookView
  width: 100%
  height: 100%
  display: flex

// Left Side

.leftSide
  height: 100%
  flex: 1 1 60%
  display: flex
  flex-flow: column
  border-right: 1px solid #ccc
  div
    width: 100%


  // Left-Top

.backButtonContainer
  height: fit-content
  display: flex
  justify-content: flex-start
  align-items: center
  border-bottom: 1px solid #ccc
  padding-left: 20px
  box-sizing: border-box

  h5
    font-size: 0.7rem
    display: inline-block
    line-height: 2
    padding-left: 20px
  svg
    display: inline-block
    height: 2rem
    width: auto

// Left-Bottom

.tableContainer
  display: flex
  align-items: center
  justify-content: center
  flex: 1 1 auto
  table
    // grid-column: 2 3
    // grid-row: 2 3
    width: fit-content
    margin: 12px
  tr
    vertical-align: top
  table, th, td
    border: none
  th
    padding-right: 40px
    padding-top: 8px
    font-size: 0.5rem
    font-family: 'Maison Neue', Arial, Helvetica, sans-serif
  th, td
    padding-bottom: 12px
    text-align: left


// .bookInformation
//   overflow-y: auto
//   padding-left: 20px
//   box-sizing: border-box

  // .imageWrapper
  //   height: fit-content
  //   width: fit-content
  //   box-shadow: 0px 0px 6px 2px #00000038

// Right Side

.rightSide
  box-sizing: border-box
  height: 100%
  flex: 1 1 40%
  display: flex
  flex-flow: column
  padding: 20px
  align-items: center
  justify-content: center
  div
    width: 100%

.coverImage
  height: calc(100% - 2.4rem)
  flex-grow: 1
  display: flex
  align-items: center
  justify-content: center
  img
    object-fit: contain
    max-width: 100%
    max-height: 100%
