.window
  // height: 100%
  // width: 100%
  z-index: 0
  position: absolute
  box-shadow: 2px 3px 3px 2px rgba(58, 51, 51, 0.702)
  display: flex
  // display: none
  flex-flow: column nowrap
  align-items: center
  justify-content: center
  border-radius: 4px
  border: 1px solid black
  background-color: white
  overflow-y: hidden
  overflow-x: hidden


.windowBody
  position: absolute
  flex: 1
  overflow-y: hidden
  overflow-x: hidden
  width: 100%
  height: calc(100% - 35px)

.fullscreen
  box-shadow: none
