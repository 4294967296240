.rightMainPanel
  overflow-y: hidden
  overflow-x: hidden
  flex: 1 1 700px
  display: grid
  grid-template-rows: auto

.headingContainer
  padding: 20px 0 15px 20px
  border-bottom: 1px solid #ccc
  // margin: 20px 0 10px 0
  width: 100%
  height: fit-content
  box-sizing: border-box
  position: sticky
  top: 0
  background-color: white

.galleryContainer
  overflow-y: auto
  width: 100%
  grid-row: 2

.gallery
  width: 100%
  // margin-top: 35px
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr))
  row-gap: 12px
  column-gap: 0
  overflow-x: hidden
  overflow-y: auto

.bookCover
  display: flex
  align-items: center
  margin: 10px
  display: flex
  align-content: center
  justify-content: center
  cursor: pointer

  .imageWrapper
    display: inline-block
    height: fit-content
    width: fit-content
    line-height: 0
    box-shadow: 0px 0px 6px 2px #00000038

    img
      max-width: 100%
      max-height: 100%
      object-fit: contain

.galleryEnd

.bouncingBall
  display: flex
  align-items: center
  justify-content: center
  svg
    width: 50px
    
